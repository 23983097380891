import { useQuery } from '@apollo/client';

import { PRODUCT_TAGS_INPUT } from '../graphql';

export const useProductTag = ({
  masterDataAccountId,
  masterDataStoreId,
}: {
  masterDataAccountId?: string;
  masterDataStoreId?: string;
}) => {
  const { data, loading, error } = useQuery(PRODUCT_TAGS_INPUT, {
    variables: {
      filter: {
        ...(masterDataAccountId && { masterDataAccountId }),
        ...(masterDataStoreId && { masterDataStoreIds: [masterDataStoreId] }),
      },
    },
  });
  return { tags: data?.tagsV2 || [], loading, error };
};
