import { ReactNode } from 'react';

import { useQuery } from '@apollo/client';

import { AccountDetailContext } from './AccountDetailContext';
import { ACCOUNT } from './graphql';

export const AccountDetailProvider = ({
  accountId,
  children,
}: {
  accountId?: string | null;
  children?: ReactNode;
}) => {
  const { data, loading, error, refetch } = useQuery(ACCOUNT, {
    skip: !accountId,
    variables: {
      id: accountId || '',
    },
  });

  return (
    <AccountDetailContext.Provider
      value={{ account: data?.MdAccount, loading, error: !!error, refetch }}
    >
      {children}
    </AccountDetailContext.Provider>
  );
};
