import { useWorkspaceCtx } from '~anyx/common/workspace';

import { InactiveStorePillContent } from './InactiveStorePillContent';
import { useGetInactiveIntegrations } from './operation';

export const InactiveStorePill = () => {
  const { workspace } = useWorkspaceCtx();
  const { inactiveIntegrationCount, loading } = useGetInactiveIntegrations({
    storeId: workspace?.storeId,
  });

  return inactiveIntegrationCount || loading ? (
    <InactiveStorePillContent
      inactiveIntegrationCount={inactiveIntegrationCount}
      loading={loading}
    />
  ) : null;
};
