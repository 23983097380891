import { gql } from '~anyx/common/graphql';

export const PRODUCT_MEDIA_UPLOAD = gql(/* GraphQL */ `
  query ProductMediaUpload($fileName: String!) {
    productMediaUpload(fileName: $fileName) {
      fileName
      signedUrl
    }
  }
`);
