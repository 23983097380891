import { useMemo } from 'react';

import {
  ATTENTION_COLOR,
  Notification,
  CheckCircleFill,
  CrossCircleFill,
  InfoCircleFill,
  WarningFill,
  NotificationProps,
} from '@any-ui-react/core';

export interface AlertBannerProps extends NotificationProps {
  type: 'info' | 'success' | 'warning' | 'error';
}

export const AlertBanner = ({
  children,
  type,
  withCloseButton = false,
  classNames,
  ...rest
}: AlertBannerProps) => {
  const [color, Icon] = useMemo(() => {
    if (type === 'info') return [ATTENTION_COLOR.info, InfoCircleFill];
    if (type === 'success') return [ATTENTION_COLOR.success, CheckCircleFill];
    if (type === 'warning') return [ATTENTION_COLOR.warning, WarningFill];

    return [ATTENTION_COLOR.error, CrossCircleFill];
  }, [type]);

  return (
    <Notification
      {...rest}
      classNames={{
        description: 'space-y-2',
        ...classNames,
      }}
      color={color}
      icon={<Icon />}
      withCloseButton={withCloseButton}
    >
      {children}
    </Notification>
  );
};
