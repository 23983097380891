import { z } from 'zod';

import { createPath } from '../../../utils';

export const CrmRoutePathKey = z.enum([
  'home',
  'welcome',
  'chat',
  'template',
  'customer',
  'oauth',
  'line',
  'instagram',
  'messenger',
  'whatsapp',
  'lazada',
  'shopee',
  'tiktok-shop',
]);
export type CrmRoutePathKey = z.infer<typeof CrmRoutePathKey>;

export const ChatPagePathKey = CrmRoutePathKey.extract([
  'chat',
  'line',
  'instagram',
  'messenger',
  'whatsapp',
  'lazada',
  'shopee',
  'tiktok-shop',
]);
export type ChatPagePathKey = z.infer<typeof ChatPagePathKey>;

export const CrmRoutePaths = (storeId = '') => {
  return {
    [CrmRoutePathKey.enum.home]: () => createPath('/:storeId/crm', { storeId }),
    [CrmRoutePathKey.enum.welcome]: () => createPath('/:storeId/crm/welcome', { storeId }),
    [CrmRoutePathKey.enum.chat]: () => ({
      ...createPath('/:storeId/crm', { storeId }),
      message: (chatId = '') => createPath('/:storeId/crm/t/:chatId', { storeId, chatId }),
    }),
    [CrmRoutePathKey.enum.template]: () => createPath('/:storeId/crm/template', { storeId }),
    [CrmRoutePathKey.enum.customer]: () => ({
      ...createPath('/:storeId/crm/customer', { storeId }),
      tags: () => createPath('/:storeId/crm/customer/tags', { storeId }),
      edit: (customerId = '') =>
        createPath('/:storeId/crm/customer/:customerId', { storeId, customerId }),
    }),
    [CrmRoutePathKey.enum.oauth]: () => ({
      lazada: () => createPath('/crm/oauth/lazada/callback'),
      whatsapp: () => createPath('/crm/oauth/whatsapp/callback'),
    }),
    [CrmRoutePathKey.enum.line]: () => ({
      ...createPath('/:storeId/crm/line', { storeId }),
      message: (chatId = '') => createPath('/:storeId/crm/line/t/:chatId', { storeId, chatId }),
      greetingMessage: () => createPath('/:storeId/crm/line/greeting-message', { storeId }),
      richMenu: () => ({
        ...createPath('/:storeId/crm/line/rich-menu', { storeId }),
        add: () => createPath('/:storeId/crm/line/rich-menu/add', { storeId }),
        edit: (richMenuId = '') =>
          createPath('/:storeId/crm/line/rich-menu/:richMenuId', {
            storeId,
            richMenuId,
          }),
      }),
      broadcast: () => ({
        ...createPath('/:storeId/crm/line/broadcast', { storeId }),
        add: () => createPath('/:storeId/crm/line/broadcast/add', { storeId }),
        edit: (broadcastId = '') =>
          createPath('/:storeId/crm/line/broadcast/:broadcastId', {
            storeId,
            broadcastId,
          }),
      }),
    }),
    [CrmRoutePathKey.enum.instagram]: () => ({
      ...createPath('/:storeId/crm/instagram', { storeId }),
      message: (chatId = '') =>
        createPath('/:storeId/crm/instagram/t/:chatId', { storeId, chatId }),
      automation: () => ({
        ...createPath('/:storeId/crm/instagram/automation', { storeId }),
        add: () => createPath('/:storeId/crm/instagram/automation/add', { storeId }),
        edit: (automationId = '') =>
          createPath('/:storeId/crm/instagram/automation/:automationId', {
            storeId,
            automationId,
          }),
      }),
    }),
    [CrmRoutePathKey.enum.messenger]: () => ({
      ...createPath('/:storeId/crm/messenger', { storeId }),
      message: (chatId = '') =>
        createPath('/:storeId/crm/messenger/t/:chatId', { storeId, chatId }),
      optIn: () => createPath('/:storeId/crm/messenger/opt-in', { storeId }),
      broadcast: () => ({
        ...createPath('/:storeId/crm/messenger/broadcast', { storeId }),
        add: () => createPath('/:storeId/crm/messenger/broadcast/add', { storeId }),
        edit: (messengerBroadcastId = '') =>
          createPath('/:storeId/crm/messenger/broadcast/:messengerBroadcastId', {
            storeId,
            messengerBroadcastId,
          }),
      }),
    }),
    [CrmRoutePathKey.enum.whatsapp]: () => ({
      ...createPath('/:storeId/crm/whatsapp', { storeId }),
      message: (chatId = '') => createPath('/:storeId/crm/whatsapp/t/:chatId', { storeId, chatId }),
      broadcast: () => ({
        ...createPath('/:storeId/crm/whatsapp/broadcast', { storeId }),
        add: () => createPath('/:storeId/crm/whatsapp/broadcast/add', { storeId }),
        edit: (broadcastId = '') =>
          createPath('/:storeId/crm/whatsapp/broadcast/:broadcastId', {
            storeId,
            broadcastId,
          }),
      }),
    }),
    [CrmRoutePathKey.enum.lazada]: () => ({
      ...createPath('/:storeId/crm/lazada', { storeId }),
      message: (chatId = '') => createPath('/:storeId/crm/lazada/t/:chatId', { storeId, chatId }),
    }),
    [CrmRoutePathKey.enum.shopee]: () => ({
      ...createPath('/:storeId/crm/shopee', { storeId }),
      message: (chatId = '') => createPath('/:storeId/crm/shopee/t/:chatId', { storeId, chatId }),
    }),
    [CrmRoutePathKey.enum['tiktok-shop']]: () => ({
      ...createPath('/:storeId/crm/tiktok-shop', { storeId }),
      message: (chatId = '') =>
        createPath('/:storeId/crm/tiktok-shop/t/:chatId', { storeId, chatId }),
    }),
  };
};
