import { gql } from '~anyx/common/graphql';

export const STORES_SELECT = gql(/* GraphQL */ `
  query MdStoresSelect(
    $pageNumber: Int!
    $pageSize: Int!
    $filter: MdStoreFilterInput
    $sortBy: MdStoreSortByInput
  ) {
    MdStores(pageNumber: $pageNumber, pageSize: $pageSize, filter: $filter, sortBy: $sortBy) {
      length
      limit
      start
      total
      items {
        id
        name
      }
    }
  }
`);
