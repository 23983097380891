import { gql } from '~anyx/common/graphql';

export const HOME_REPORT_PRODUCT_TABLE = gql(/* GraphQL */ `
  query HomeReportProductTable($filter: HomeReportFilterInput!) {
    reportHomeTop5Products(filter: $filter) {
      data {
        currency
        grossSales
        image
        orderCount
        productId
        productName
      }
    }
  }
`);
