import { gql } from '~anyx/common/graphql';

export const DDI_HOME_REPORT_CHART = gql(/* GraphQL */ `
  query DDIHomeReportChart($input: DdiHomeReportInput!) {
    ddiHomeReportChart(input: $input) {
      currency
      grossSales
      netSales
      orders
    }
  }
`);
