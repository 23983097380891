import { gql } from '~anyx/common/graphql';

export const PRODUCT_SET_VARIANTS_VARIANT_SELECTOR = gql(/* GraphQL */ `
  query ProductSetVariantsVariantSelector(
    $filter: ProductVariantForSetFilterInput!
    $pageSize: Int!
    $pageNumber: Int!
  ) {
    productVariantsForProductSet(filter: $filter, pageSize: $pageSize, pageNumber: $pageNumber) {
      total
      productVariants {
        productName
        variantName
        productVariantId
        media
        productCode
      }
    }
  }
`);
