import { gql } from '~anyx/common/graphql';

export const DDI_HOME_REPORT_SUMMARY = gql(/* GraphQL */ `
  query DDIHomeReportSummary($input: DdiHomeReportInput!) {
    ddiHomeReportSummary(input: $input) {
      grossSales {
        diff
        diffPercentage
        value
      }
      orders {
        diff
        diffPercentage
        value
      }
      netSales {
        diff
        diffPercentage
        value
      }
      currency
    }
  }
`);
