import { gql } from '~anyx/common/graphql';

export const RAKUTEN_SALES_REPORT_TABLE = gql(/* GraphQL */ `
  query RakutenSalesReportTable($input: RakutenSalesReportTableInput!) {
    rakutenSalesReportTable(input: $input) {
      items {
        conversionRate
        currency
        date
        orderCount
        sessions
        totalSales
      }
      meta {
        length
        limit
        start
        total
      }
      total {
        conversionRate
        orderCount
        sessions
        totalSales
      }
    }
  }
`);
