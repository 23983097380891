import { gql } from '~anyx/common/graphql';

export const DEFAULT_ACCOUNT = gql(/* GraphQL */ `
  query DefaultAccount(
    $pageNumber: Int!
    $pageSize: Int!
    $filter: MdAccountFilterInput
    $sortBy: MdAccountSortByInput
  ) {
    MdAccounts(pageNumber: $pageNumber, pageSize: $pageSize, filter: $filter, sortBy: $sortBy) {
      items {
        id
      }
    }
  }
`);
