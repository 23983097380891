import { gql } from '~anyx/common/graphql';

export const YAHOO_SALES_REPORT_TABLE = gql(/* GraphQL */ `
  query YahooSalesReportTable($input: YahooSalesReportTableInput!) {
    yahooSalesReportTable(input: $input) {
      items {
        avgSpendPerCustomer
        currency
        date
        orderCount
        totalSales
        unitSoldCount
      }
      meta {
        length
        limit
        start
        total
      }
      total {
        avgSpendPerCustomer
        orderCount
        totalSales
        unitSoldCount
      }
    }
  }
`);
