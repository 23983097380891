import { gql } from '~anyx/common/graphql';

export const INIT_WORKSPACE = gql(/* GraphQL */ `
  query InitWorkspace(
    $pageNumber: Int!
    $pageSize: Int!
    $filter: MdStoreFilterInput
    $sortBy: MdStoreSortByInput
  ) {
    MdStores(pageNumber: $pageNumber, pageSize: $pageSize, filter: $filter, sortBy: $sortBy) {
      items {
        ...WorkspaceData
      }
    }
  }
`);

export const VALIDATE_WORKSPACE = gql(/* GraphQL */ `
  query ValidateWorkspace($mdStoreId: Long!) {
    MdStore(id: $mdStoreId) {
      ...WorkspaceData
    }
  }
`);

export const WORKSPACE_DATA = gql(/* GraphQL */ `
  fragment WorkspaceData on MdStore {
    name
    account {
      id
      name
      product
    }
    id
    crmStore {
      isCrmEnabled
      chats {
        type
      }
    }
  }
`);
