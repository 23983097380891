import { gql } from '~anyx/common/graphql';

export const TIKTOK_SALES_REPORT_SUMMARY = gql(/* GraphQL */ `
  query TikTokSalesReportSummary($input: TikTokSalesReportSummaryInput!) {
    tikTokSalesReportSummary(input: $input) {
      currency
      itemsOrdered {
        diff
        diffPercentage
        value
      }
      sales {
        diff
        diffPercentage
        value
      }
      unitsOrdered {
        diff
        diffPercentage
        value
      }
    }
  }
`);
