import * as Sentry from '@sentry/react';
import { Analytics, initializeAnalytics } from 'firebase/analytics';
import { FirebaseApp, FirebaseError, initializeApp } from 'firebase/app';
import { Firestore, getFirestore } from 'firebase/firestore';
import { getRemoteConfig, fetchAndActivate, RemoteConfig } from 'firebase/remote-config';

import { shouldCaptureFirebaseError } from './error';
import { FirebaseConfig, FirebaseRemoteConfig } from './types';

export let app: FirebaseApp;
export let analyticInstance: Analytics;
export let remoteConfigInstance: RemoteConfig;
export let firestoreInstance: Firestore;

export const initFirebase = async (
  config: FirebaseConfig,
  options?: {
    remoteConfig?: FirebaseRemoteConfig;
  }
): Promise<boolean> => {
  app = initializeApp(config);
  analyticInstance = initializeAnalytics(app);
  remoteConfigInstance = getRemoteConfig(app);
  firestoreInstance = getFirestore(app);

  if (options?.remoteConfig?.minimumFetchIntervalMillis !== undefined) {
    remoteConfigInstance.settings.minimumFetchIntervalMillis =
      options?.remoteConfig?.minimumFetchIntervalMillis;
  }

  try {
    await fetchAndActivate(remoteConfigInstance);

    return true;
  } catch (error) {
    let shouldCaptureError = true;

    if (error instanceof FirebaseError) {
      shouldCaptureError = shouldCaptureFirebaseError(error);
    }

    if (shouldCaptureError) {
      Sentry.captureException(error);
    }

    return false;
  }
};
