import { useTranslation } from 'react-i18next';

import { Button } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';

import { AlertIcon } from '~anyx/shared/icons';
import { AlertBanner } from '~anyx/shared/ui';
import { DateUtils } from '~anyx/shared/utils';

import { useMaintenanceStatus } from './hooks';

interface MaintenanceNotifierProps {
  checkInterval?: number;
}

const STORAGE_KEY = 'maintenance-notifier-dismissed';

export const MaintenanceNotifier = ({ checkInterval = 3600000 }: MaintenanceNotifierProps) => {
  const { t } = useTranslation(['core', 'shared']);
  const [dismissed, setDismissed] = useLocalStorage<string[]>({
    key: STORAGE_KEY,
    defaultValue: [],
    getInitialValueInEffect: false,
  });
  const { data: maintenanceInfo } = useMaintenanceStatus({
    enabled: true,
    refetchInterval: checkInterval,
  });

  const handleDismiss = () => {
    if (!maintenanceInfo.maintenanceId) return;
    setDismissed([...dismissed, maintenanceInfo.maintenanceId]);
  };

  if (
    !maintenanceInfo.startTime ||
    (maintenanceInfo.maintenanceId && dismissed.includes(maintenanceInfo.maintenanceId))
  )
    return null;

  return (
    <div className="fixed top-4 z-50 mx-auto mt-8 flex w-full">
      <AlertBanner
        icon={<AlertIcon className="size-4" />}
        type="warning"
        title={t('core.component.maintenanceNotifier.title', { ns: 'core' })}
        withCloseButton={false}
        className="m-auto justify-center shadow-lg"
      >
        <div className="flex flex-col">
          <p>
            {t('core.component.maintenanceNotifier.description', {
              ns: 'core',
              startTime: DateUtils.formatDateInTimeZone(
                new Date(maintenanceInfo.startTime.seconds * 1000),
                {
                  formatStr: DateUtils.FORMATS.LHHmm,
                }
              ),
              duration: maintenanceInfo.duration,
            })}
          </p>
          <small>{t('core.component.maintenanceNotifier.note', { ns: 'core' })}</small>
          <Button
            variant="default"
            className="mt-2 self-end"
            color="gray"
            size="xs"
            onClick={handleDismiss}
          >
            {t('shared.button.dismiss', { ns: 'shared' })}
          </Button>
        </div>
      </AlertBanner>
    </div>
  );
};
