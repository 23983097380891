import { gql } from '~anyx/common/graphql';

export const YAHOO_SALES_REPORT_CHART = gql(/* GraphQL */ `
  query YahooSalesReportChart($input: YahooSalesReportChartInput!) {
    yahooSalesReportChart(input: $input) {
      avgSpendPerCustomer
      currency
      orderCount
      totalSales
      unitSoldCount
    }
  }
`);
