import { gql } from '~anyx/common/graphql';

export const DDI_HOME_REPORT_PRODUCT_TABLE = gql(/* GraphQL */ `
  query DDIHomeReportProductTable($input: DdiHomeReportInput!) {
    ddiHomeReportTopProducts(input: $input) {
      currency
      products {
        grossSales
        netSales
        orders
        productImageUrl
        productId
        productName
      }
    }
  }
`);
