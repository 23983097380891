import { gql } from '~anyx/common/graphql';

export const STORES_CHANNELS = gql(/* GraphQL */ `
  query MdStoresChannels(
    $pageNumber: Int!
    $pageSize: Int!
    $filter: MdStoreFilterInput
    $sortBy: MdStoreSortByInput
  ) {
    MdStores(pageNumber: $pageNumber, pageSize: $pageSize, filter: $filter, sortBy: $sortBy) {
      items {
        id
        channels {
          id
          type
          isManualChannel
          data {
            ... on AmazonSaleChannelData {
              amazonMarketplace: marketplace
            }
            ... on LazadaSaleChannelData {
              lazadaMarketplace: marketplace
            }
            ... on Qoo10SaleChannelData {
              qoo10Marketplace: marketplace
            }
            ... on ShopeeSaleChannelData {
              showReconnectButton
              expiresAt
              shopeeMarketplace: marketplace
            }
            ... on TikTokSaleChannelData {
              tiktokMarketplace: marketplace
            }
          }
        }
      }
    }
  }
`);
