import { t } from 'i18next';

import {
  ReportChartTab,
  ReportDimensions,
  SaleChannelReportTableFilterInput,
} from '~anyx/common/graphql';
import {
  AnalyticsMetricOption,
  HomeMetricOption,
  MetricData,
  NumberFormat,
  ReportMetricMappingKey,
  ReportType,
  ReportUtils,
  StoreChartId,
  calculatePercentage,
  dimensionList,
} from '~anyx/feature/report';
import { NumberUtils, ObjectUtils } from '~anyx/shared/utils';

import { ReportChart, ReportTable } from '../modules/sales-channel/pages/analytics/type';

export const computeStoreChartData = ({
  filterInputs,
  reportChart,
  reportTable: totalData,
  metricList,
}: {
  filterInputs: SaleChannelReportTableFilterInput;
  reportChart?: ReportChart;
  reportTable?: ReportTable;
  metricList: Record<
    AnalyticsMetricOption | HomeMetricOption,
    {
      mappingKey: ReportMetricMappingKey;
      numberFormat: NumberFormat;
    }
  >;
}) => {
  const { dimensions: dimension, chartTab } = filterInputs;
  if (chartTab !== ReportChartTab.STORE) {
    return {
      reportStoreChart: {},
      storeList: [],
      storeIds: [],
    };
  }

  const isDowDimension = dimension === ReportDimensions.DOW;

  const {
    list: chartList = [],
    stores: storeList = [],
    channels: channelList = [],
    currency,
  } = reportChart || {};

  // Get x-axis data
  const sortedDimensionArray: string[] = [
    ...new Set(chartList.map((item) => item[dimensionList[dimension]])),
  ].sort((a, b): number => (a < b ? -1 : 1));

  const sortedChannels = ObjectUtils.alphabeticalOrder(channelList);

  const sortedTotalDimensionArray = (totalData?.list || [])
    .map((item) => item)
    .filter((item) =>
      isDowDimension ? sortedDimensionArray.map((item) => String(item)).includes(item.metric) : true
    )
    .sort((a, b) => (a.metric < b.metric ? -1 : 1));

  const calculatedGrossSalesByStore = reportChart
    ? chartList.reduce((acc, { masterDataStoreId, grossSales, storeName, channel }) => {
        const id = masterDataStoreId ?? '';
        const { grossSales: accGrossSales = 0 } = acc[id] ?? {};
        if (acc[id]) {
          acc[id] = {
            grossSales: accGrossSales + NumberUtils.toNumber(grossSales),
            name: storeName ?? t('shared.button.unknown', { ns: 'shared' }),
            channel: channel ?? '',
          };
        } else {
          acc[id] = {
            grossSales: NumberUtils.toNumber(grossSales),
            name: storeName ?? t('shared.button.unknown', { ns: 'shared' }),
            channel: channel ?? '',
          };
        }
        return acc;
      }, {} as Record<string, { grossSales: number; name: string; channel: string }>)
    : {};

  const sortedStoreByGrossSales = Object.entries(calculatedGrossSalesByStore)
    .map(([key, value]) => {
      return {
        id: key,
        ...value,
      };
    })
    .sort((a, b) => b.grossSales - a.grossSales);

  const storesToFillIn = storeList
    .slice(0, 10)
    .filter((store) => !sortedStoreByGrossSales.map((store) => store.id).includes(store.id));
  const storesWithZeroData = storesToFillIn.map((store) => ({
    id: store.id,
    grossSales: 0,
    name: store.name,
    channel: '',
  }));
  const topTenStores = [...sortedStoreByGrossSales, ...storesWithZeroData].slice(0, 10);
  const aggregatedStore =
    sortedStoreByGrossSales.length > 10
      ? sortedStoreByGrossSales.slice(10).reduce(
          (acc, { grossSales, channel }) => {
            return {
              id: StoreChartId.OTHER,
              grossSales: acc.grossSales + grossSales,
              name: t('shared.entity.other', { ns: 'shared', count: 2 }),
              channel: channel,
            };
          },
          {
            grossSales: 0,
          } as { grossSales: number; name: string; id: string; channel: string }
        )
      : {
          id: StoreChartId.OTHER,
          grossSales: 0,
          name: t('shared.entity.other', { ns: 'shared', count: 2 }),
          channel: '',
        };

  const newStoreList = storeList.length > 10 ? [...topTenStores, aggregatedStore] : topTenStores;

  const storeIds = newStoreList.map((item) => item.id);
  const otherStoreIds = sortedStoreByGrossSales.slice(10).map((item) => item.id);
  const getStoreChartData = (dimension: ReportDimensions) => {
    const isChannelsDimension = dimension === ReportDimensions.CHANNELS;

    const getMetricValue = (
      storeId: string,
      value: string,
      channel?: string
    ): Record<string, unknown> => {
      if (storeId === StoreChartId.OTHER) {
        const otherStoreMetrics = chartList
          .filter((item) => {
            return (
              item[dimensionList[dimension]] === value &&
              item.masterDataStoreId &&
              otherStoreIds.includes(item.masterDataStoreId)
            );
          })
          .reduce(
            (
              acc,
              {
                grossSales,
                grossProfit,
                avgOrderValues,
                marginalProfit,
                orders,
                sessions,
                advertisingCost,
              }
            ) => {
              return {
                grossSales: acc.grossSales + NumberUtils.toNumber(grossSales),
                sessions: acc.sessions + NumberUtils.toNumber(sessions),
                orders: acc.orders + NumberUtils.toNumber(orders),
                avgOrderValues: acc.avgOrderValues + NumberUtils.toNumber(avgOrderValues),
                grossProfit: acc.grossProfit + NumberUtils.toNumber(grossProfit),
                marginalProfit: acc.marginalProfit + NumberUtils.toNumber(marginalProfit),
                advertisingCost: acc.advertisingCost + NumberUtils.toNumber(advertisingCost),
              };
            },
            {
              grossSales: 0,
              avgOrderValues: 0,
              grossProfit: 0,
              marginalProfit: 0,
              orders: 0,
              sessions: 0,
              advertisingCost: 0,
            }
          );

        const othersItem = {
          ...otherStoreMetrics,
          marginalProfitRate: calculatePercentage(
            otherStoreMetrics.marginalProfit,
            otherStoreMetrics.grossSales
          ),
          cvr: calculatePercentage(otherStoreMetrics.orders, otherStoreMetrics.sessions),
          roas: calculatePercentage(
            otherStoreMetrics.grossSales,
            otherStoreMetrics.advertisingCost
          ),
          grossProfitRate: calculatePercentage(
            otherStoreMetrics.grossProfit,
            otherStoreMetrics.grossSales
          ),
          masterDataStoreId: StoreChartId.OTHER,
        };

        return othersItem;
      } else {
        if (isChannelsDimension) {
          const storeMetrics = chartList
            .filter((item) => {
              return item.channel === channel && item.masterDataStoreId === storeId;
            })
            .reduce(
              (
                acc,
                {
                  grossSales,
                  sessions,
                  orders,
                  avgOrderValues,
                  grossProfit,
                  marginalProfit,
                  advertisingCost,
                  advertisingRevenue,
                }
              ) => {
                return {
                  grossSales: acc.grossSales + NumberUtils.toNumber(grossSales),
                  sessions: acc.sessions + NumberUtils.toNumber(sessions),
                  orders: acc.orders + NumberUtils.toNumber(orders),
                  avgOrderValues: acc.avgOrderValues + NumberUtils.toNumber(avgOrderValues),
                  grossProfit: acc.grossProfit + NumberUtils.toNumber(grossProfit),
                  marginalProfit: acc.marginalProfit + NumberUtils.toNumber(marginalProfit),
                  advertisingCost: acc.advertisingCost + NumberUtils.toNumber(advertisingCost),
                  advertisingRevenue:
                    acc.advertisingRevenue + NumberUtils.toNumber(advertisingRevenue),
                };
              },
              {
                grossSales: 0,
                avgOrderValues: 0,
                grossProfit: 0,
                marginalProfit: 0,
                orders: 0,
                sessions: 0,
                advertisingCost: 0,
                advertisingRevenue: 0,
              }
            );

          const othersItem = {
            ...storeMetrics,
            marginalProfitRate: calculatePercentage(
              storeMetrics.marginalProfit,
              storeMetrics.grossSales
            ),
            cvr: calculatePercentage(storeMetrics.orders, storeMetrics.sessions),
            roas: calculatePercentage(
              storeMetrics.advertisingRevenue,
              storeMetrics.advertisingCost
            ),
            grossProfitRate: calculatePercentage(storeMetrics.grossProfit, storeMetrics.grossSales),
            masterDataStoreId: StoreChartId.OTHER,
          };

          return othersItem;
        } else {
          return (
            chartList.find((item) => {
              return item[dimensionList[dimension]] === value && item.masterDataStoreId === storeId;
            }) || {}
          );
        }
      }
    };

    // Loop through metricList to get metric data
    const chartData = Object.entries(metricList).reduce(
      (acc, [key, { numberFormat, mappingKey }]) => {
        const metricDataArray = isChannelsDimension ? sortedChannels : sortedDimensionArray;

        const getStoreData = (value: string, index: number, channel?: string) => {
          const metricValueArray: Record<string, unknown>[] = [];
          const totalMetricValue: { readonly [key: string]: string | null } =
            (isChannelsDimension
              ? (totalData?.list || [])[index]
              : sortedTotalDimensionArray[index]) || {};

          const storeData = newStoreList.reduce((storeData, store) => {
            const storeId = store.id;
            const metricValue = getMetricValue(storeId, value, channel);
            metricValueArray.push(metricValue);

            return {
              ...storeData,
              [storeId]: NumberUtils.toNumber(metricValue[mappingKey]),
            };
          }, {} as { id: string; name: string; grossSales: number; channel: string });

          return {
            ...storeData,
            key: ReportUtils.formatMetric({
              dimension,
              value,
              type: ReportType.CHART,
            }),
            name: t('gql.enum.reportSummary', { ns: 'gql', metric: key }),
            numberFormat,
            currency,
            total: NumberUtils.toNumber(totalMetricValue?.[mappingKey]),
          };
        };

        // Date or Channel array
        const metricData = metricDataArray.map((value, index) => {
          return getStoreData(value, index, isChannelsDimension ? value : undefined);
        });

        return {
          ...acc,
          [key]: metricData,
        };
      },
      {}
    );

    return chartData;
  };

  const reportStoreChart: Record<AnalyticsMetricOption | HomeMetricOption, MetricData> =
    getStoreChartData(dimension);

  return {
    reportStoreChart,
    storeIds,
    storeList: newStoreList,
  };
};
