import React from 'react';
import { RouteObject } from 'react-router';

const CustomerTagListPage = React.lazy(() => import('./pages/customer-tag-list'));
const CustomerListPage = React.lazy(() => import('./pages/list'));
const CustomerEditPage = React.lazy(() => import('./pages/edit'));
const CustomerConnectAnyChat = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.CustomerConnectAnyChat }))
);

export const routes: RouteObject[] = [
  {
    path: 'customer',
    element: <CustomerConnectAnyChat />,
    children: [
      {
        index: true,
        element: <CustomerListPage />,
      },
      {
        path: 'tags',
        element: <CustomerTagListPage />,
      },
      {
        path: ':customerId',
        element: <CustomerEditPage />,
      },
    ],
  },
];
