import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import { useLocalStorage } from '@mantine/hooks';

import { StoreRoutePath } from '~anyx/common/routing';
import { useWorkspaceCtx } from '~anyx/common/workspace';
import { AlertBanner } from '~anyx/shared/ui';

import { useStoreCategory } from './hooks';

const STORAGE_KEY = 'store-category-update-dismissed';

export const StoreCategoryBannerTmp = () => {
  const { t } = useTranslation('core');
  const { workspace: { storeId = '' } = {} } = useWorkspaceCtx();

  const [dismissedStoreIds, setDismissedStoreIds] = useLocalStorage<string[]>({
    key: STORAGE_KEY,
    defaultValue: [],
    getInitialValueInEffect: false,
  });

  const isDismissed = dismissedStoreIds?.includes(storeId);
  const { storeData } = useStoreCategory({ skip: isDismissed });

  const { category } = storeData?.MdStore || {};

  const handleDismiss = () => {
    setDismissedStoreIds((prevState = []) => {
      if (!storeId) return prevState;
      return [...new Set([...prevState, storeId])];
    });
  };

  if (isDismissed) {
    return null;
  }

  return (
    <AlertBanner
      type="info"
      title={t('core.page.home.storeCategoryBanner.title', {
        category: t('gql.enum.storeCategory', {
          ns: 'gql',
          category,
        }),
      })}
      withCloseButton
      onClose={handleDismiss}
      className="mb-4"
    >
      <Trans
        i18nKey="core.page.home.storeCategoryBanner.content"
        ns="core"
        components={[
          <Link
            to={
              StoreRoutePath().edit({
                id: storeId,
              }).path
            }
            className="text-link hover:underline"
          />,
        ]}
        parent="p"
      />
    </AlertBanner>
  );
};
