import { gql } from '~anyx/common/graphql';

export const YAHOO_SALES_REPORT_SUMMARY = gql(/* GraphQL */ `
  query YahooSalesReportSummary($input: YahooSalesReportSummaryInput!) {
    yahooSalesReportSummary(input: $input) {
      avgSpendPerCustomer {
        diff
        diffPercentage
        value
      }
      currency
      orderCount {
        diff
        diffPercentage
        value
      }
      totalSales {
        diff
        diffPercentage
        value
      }
      unitSoldCount {
        diff
        diffPercentage
        value
      }
    }
  }
`);
