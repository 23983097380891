import { Loader } from '@any-ui-react/core';

import { LoadWrapper } from '~anyx/shared/ui';

export const InactiveStorePillContent = ({
  inactiveIntegrationCount,
  loading,
}: {
  inactiveIntegrationCount: number;
  loading: boolean;
}) => {
  return (
    <LoadWrapper loading={loading} loadingTemplate={<Loader size="xs" />}>
      <div className="bg-failure text-3xs flex h-4 w-fit max-w-6 items-center justify-center rounded-full px-1.5 text-white">
        {inactiveIntegrationCount > 20 ? '20+' : inactiveIntegrationCount}
      </div>
    </LoadWrapper>
  );
};
