import { gql } from '~anyx/common/graphql';

export const AMAZON_SALES_SUMMARY = gql(/* GraphQL */ `
  query AmazonSalesSummary($input: AmazonSalesSummaryInput!) {
    amazonSalesSummary(input: $input) {
      averageNumberOfUnitsPerOrderItem {
        diff
        diffPercentage
        value
      }
      currency
      itemAverageCost {
        diff
        diffPercentage
        value
      }
      itemsOrdered {
        diff
        diffPercentage
        value
      }
      sales {
        diff
        diffPercentage
        value
      }
      unitAverageCost {
        diff
        diffPercentage
        value
      }
      unitsOrdered {
        diff
        diffPercentage
        value
      }
    }
  }
`);
