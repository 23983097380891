import { useCallback } from 'react';

import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';

import { MdStoreSelectQuery, MdStoreSelectQueryVariables } from '~anyx/common/graphql';

import { STORE_SELECT } from '../graphql';

export type MdStoreSelectQueryOptions = LazyQueryHookOptions<
  MdStoreSelectQuery,
  MdStoreSelectQueryVariables
>;
export const useStoreSelect = () => {
  const [queryMdStore, { loading }] = useLazyQuery(STORE_SELECT);

  const getStoreDetails = useCallback(
    async ({ storeId }: { storeId: string }) => {
      if (!storeId) return;

      const { data } = await queryMdStore({
        variables: {
          mdStoreId: storeId,
        },
      });
      return data?.MdStore;
    },
    [queryMdStore]
  );

  return {
    loading,
    getStoreDetails,
  };
};
