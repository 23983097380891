import { gql } from '~anyx/common/graphql';

export const SHOPIFY_SALES_REPORT_SUMMARY = gql(/* GraphQL */ `
  query ShopifySalesReportSummary($input: ShopifySalesReportSummaryInput!) {
    shopifySalesReportSummary(input: $input) {
      currency
      discounts {
        diff
        diffPercentage
        value
      }
      grossSales {
        diff
        diffPercentage
        value
      }
      netSales {
        diff
        diffPercentage
        value
      }
      orderCount {
        diff
        diffPercentage
        value
      }
      returns {
        diff
        diffPercentage
        value
      }
      shipping {
        diff
        diffPercentage
        value
      }
      taxes {
        diff
        diffPercentage
        value
      }
      totalSales {
        diff
        diffPercentage
        value
      }
    }
  }
`);
