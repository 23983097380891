import { gql } from '~anyx/common/graphql';

export const UPDATE_SELF = gql(/* GraphQL */ `
  mutation mdUpdateSelf($input: MdUpdateUserInput!) {
    MdUpdateUser(input: $input) {
      accessAllStores
      storeIds
      accountIds
      auth0UserId
      canManageUsers
      countryId
      createdAt
      currencyId
      email
      firstName
      id
      languageId
      lastName
      role
      timezoneId
      updatedAt
    }
  }
`);
