import { gql } from '~anyx/common/graphql';

export const RAKUTEN_SALES_REPORT_CHART = gql(/* GraphQL */ `
  query RakutenSalesReportChart($input: RakutenSalesReportChartInput!) {
    rakutenSalesReportChart(input: $input) {
      conversionRate
      currency
      orderCount
      sessions
      totalSales
    }
  }
`);
