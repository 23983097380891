import { useLazyQuery } from '@apollo/client';

import {
  MdAccountFilterInput,
  MdAccountSortByFieldEnum,
  useLoadOptions,
  MdAccountsSelectQuery,
  MdAccountsSelectQueryVariables,
} from '~anyx/common/graphql';

import { ACCOUNTS_SELECT } from '../graphql';

export const useAccountsSelect = <T = string>({ filters }: { filters?: MdAccountFilterInput }) => {
  const [queryMdAccountList] = useLazyQuery(ACCOUNTS_SELECT);
  const { loadOptions } = useLoadOptions<
    MdAccountsSelectQuery['MdAccounts']['items'][0],
    T,
    Omit<MdAccountsSelectQuery, '__typename'>,
    MdAccountsSelectQueryVariables
  >({
    labelFormatter: (item) => item.name,
    valueFormatter: (item) => item.id as unknown as T,
    listQuery: {
      variables: {
        sortBy: {
          descending: false,
          field: MdAccountSortByFieldEnum.COMPANY_NAME,
        },
        filter: {
          ...filters,
        },
        options: {
          fetchPolicy: 'network-only',
        },
      },
      scope: 'MdAccounts',
      lazyQuery: queryMdAccountList,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any,
  });
  return {
    loadOptions,
  };
};
