import { gql } from '~anyx/common/graphql';

export const TIKTOK_SALES_REPORT_DETAILED_REPORT = gql(/* GraphQL */ `
  query TikTokSalesReportDetailedReport($input: TikTokSalesReportDetailedReportInput!) {
    tikTokSalesReportDetailedReport(input: $input) {
      items {
        currency
        date
        itemsOrdered
        sales
        unitsOrdered
      }
      meta {
        length
        limit
        start
        total
      }
      total {
        currency
        itemsOrdered
        sales
        unitsOrdered
      }
    }
  }
`);
