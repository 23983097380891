import React from 'react';
import { Navigate, RouteObject } from 'react-router';

import { Auth0Guard } from '~anyx/common/auth';
import { Auth0Permissions, Restricted } from '~anyx/common/permission';
import { ShipmentRoutePath } from '~anyx/common/routing';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const ShipmentsTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.ShipmentsTemplate }))
);

const ShipmentsListPage = React.lazy(() =>
  import('./pages/list').then((module) => ({ default: module.ShipmentsListPage }))
);

const ShipmentDetailPage = React.lazy(() =>
  import('./pages/details').then((module) => ({ default: module.ShipmentDetailPage }))
);

const ShipmentAddPage = React.lazy(() =>
  import('./pages/add').then((module) => ({ default: module.ShipmentAddPage }))
);

export const routes: RouteObject[] = [
  {
    path: 'shipments',
    element: (
      <Auth0Guard>
        <Restricted
          permissions={[[Auth0Permissions.CAN_USE_SHIPPING]]}
          fallback={<UnauthorizedMessage />}
        >
          <ShipmentsTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'list',
        element: <ShipmentsListPage />,
      },
      {
        path: ':accountId?/:orderId/new',
        element: <ShipmentAddPage />,
      },
      {
        path: ':accountId?/:orderId',
        element: <ShipmentDetailPage />,
      },
      {
        index: true,
        element: (
          <Navigate to={ShipmentRoutePath({ workspaceStoreId: null }).list().path} replace={true} />
        ),
      },
    ],
  },
];
