import {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useCallback,
  SetStateAction,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  DropZone as AnyUIDropZone,
  DefaultAddButton,
  DefaultPlaceholder,
  DropEvent,
  DropZoneProps,
  DropZoneViewMode,
  FileRejection,
  PlaceholderRenderProps,
  UploadableFile,
} from '@any-ui-react/dropzone';

import {
  FileRestriction,
  FileTypes,
  FilesUtils,
  useFileUploadErrorTranslationMapper,
} from '~anyx/shared/utils';

type Optional = 'onDropRejected' | 'accept' | 'maxSize' | 'addButtonRender' | 'placeholderRender';
interface AnyUIDropZoneProps extends Omit<DropZoneProps, Optional> {
  restrictions: FileRestriction;
  onDropRejected?: (message: string) => void;
}

export const DropZone = forwardRef((props: AnyUIDropZoneProps, ref: ForwardedRef<unknown>) => {
  useImperativeHandle(
    ref,
    () => {
      return {
        focus() {
          return;
        },
      };
    },
    []
  );

  // Use a ref to track if we're in the middle of an update cycle
  const isUpdatingRef = useRef(false);

  const {
    width = '100%',
    viewMode = DropZoneViewMode.CUSTOM,
    restrictions,
    onDropRejected,
    value,
    onChange,
    onDrop,
    ...restProps
  } = props;

  const { getErrorMessages, getErrorCodes } = useFileUploadErrorTranslationMapper();
  const { t } = useTranslation();

  const handleFileRejection = useCallback(
    (fileRejections: FileRejection[]) => {
      const errorCodes = getErrorCodes(fileRejections);
      const message = getErrorMessages(errorCodes, restrictions);
      onDropRejected?.(message);
    },
    [getErrorCodes, getErrorMessages, onDropRejected, restrictions]
  );

  const handleDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
      if (onDrop) {
        onDrop(acceptedFiles, fileRejections, event);
      }
    },
    [onDrop]
  );

  // Break the circular update pattern by using a ref to prevent redundant updates
  const handleChange = useCallback(
    (newValue: SetStateAction<UploadableFile[]>) => {
      if (isUpdatingRef.current) return;

      if (onChange) {
        isUpdatingRef.current = true;
        onChange(newValue);
        // Reset the flag after the current call stack completes
        setTimeout(() => {
          isUpdatingRef.current = false;
        }, 0);
      }
    },
    [onChange]
  );

  const extensions = useMemo(() => {
    return Object.values(restrictions.types || {})
      .map((type) => (type[0] ? type[0].toUpperCase().split('.').pop() : []))
      .join(', ');
  }, [restrictions.types]);

  const fileType = useMemo(() => {
    if (Object.keys(restrictions.types || {}).some((element) => element.includes('image'))) {
      return FileTypes.IMAGE;
    }
    return FileTypes.FILE;
  }, [restrictions.types]);

  const memoizedValue = useMemo(() => {
    if (!value || value.length === 0) return [];

    return value.map((file) => ({
      id: file.id,
      name: file.name,
      previewImage: file.previewImage,
      status: file.status,
      localFile: file.localFile,
      remoteUrl: file.remoteUrl,
    }));
  }, [value]);

  const renderAddButton = useCallback(
    (buttonProps: PlaceholderRenderProps) => (
      <div className="text-4xs m-auto sm:text-sm">
        <DefaultAddButton
          {...buttonProps}
          text={t('shared.action.add', {
            ns: 'shared',
            entity: t('shared.entity.file', { ns: 'shared', count: 1 }),
          })}
        />
      </div>
    ),
    [t]
  );

  const renderPlaceholder = useCallback(
    (placeholderProps: PlaceholderRenderProps) => (
      <DefaultPlaceholder
        {...placeholderProps}
        buttonText={t('shared.action.upload', {
          ns: 'shared',
          entity: t('shared.entity.file', { ns: 'shared', count: 1 }),
        })}
        placeholderTitle={t('shared.fileUploader.content', {
          ns: 'shared',
          fileType,
          entity: t('shared.entity.file', { ns: 'shared', count: 1 }),
        })}
        placeholderDescription={
          <>
            {!!restrictions.size &&
              t('shared.fileUploader.maxSize', {
                ns: 'shared',
                fileType,
                size: `${restrictions.size / FilesUtils.MEGABYTE}MB`,
              })}
            <br />
            {restrictions.types &&
              t('shared.fileUploader.supportedExtension', {
                ns: 'shared',
                fileType,
                extensions,
              })}
          </>
        }
      />
    ),
    [t, fileType, restrictions.size, restrictions.types, extensions]
  );

  return (
    <AnyUIDropZone
      {...restProps}
      width={width}
      viewMode={viewMode}
      accept={restrictions.types}
      maxSize={restrictions.size}
      onDropRejected={handleFileRejection}
      onDrop={handleDrop}
      onChange={handleChange}
      value={memoizedValue}
      addButtonRender={renderAddButton}
      placeholderRender={renderPlaceholder}
    />
  );
});
