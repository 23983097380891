import { gql } from '~anyx/common/graphql';

export const ACCOUNT = gql(/* GraphQL */ `
  query MdAccount($id: Long!) {
    MdAccount(id: $id) {
      address1
      address2
      cityName
      countryId
      createdAt
      hubspotCustomerId
      id
      name
      netsuiteVendorId
      phoneNumber
      phoneNumberCountryId
      product
      provinceId
      status
      updatedAt
      websiteUrl
      zipcode
    }
  }
`);
