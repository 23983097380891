import { useQuery } from '@apollo/client';

import { useWorkspaceCtx } from '~anyx/common/workspace';

import { STORE_CATEGORY } from '../graphql';

export const useStoreCategory = ({ skip }: { skip: boolean }) => {
  const { workspace: { storeId = '' } = {} } = useWorkspaceCtx();
  const { data: storeData } = useQuery(STORE_CATEGORY, {
    skip: !storeId || skip,
    variables: {
      mdStoreId: storeId,
    },
  });
  return { storeData };
};
