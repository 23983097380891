import { gql } from '~anyx/common/graphql';

export const TOKOPEDIA_SALES_DETAILED_REPORT = gql(/* GraphQL */ `
  query TokopediaSalesDetailedReport($input: TokopediaSalesDetailedReportInput!) {
    tokopediaSalesDetailedReport(input: $input) {
      items {
        currency
        date
        orders
        sales
      }
      meta {
        length
        limit
        start
        total
      }
      total {
        currency
        orders
        sales
      }
    }
  }
`);
