import { gql } from '~anyx/common/graphql';

export const SHOPEE_SALES_DETAILED_REPORT = gql(/* GraphQL */ `
  query ShopeeSalesDetailedReport($input: ShopeeSalesDetailedReportInput!) {
    shopeeSalesDetailedReport(input: $input) {
      items {
        currency
        date
        orders
        sales
        salesPerOrder
        visitors
      }
      meta {
        length
        limit
        start
        total
      }
      total {
        currency
        orders
        sales
        salesPerOrder
        visitors
      }
    }
  }
`);
