import { useEffect, useState, useCallback } from 'react';

import * as Sentry from '@sentry/react';
import { collection, query, where, getDocs, orderBy, limit, Timestamp } from 'firebase/firestore';

import { useFirestore } from '~anyx/external/firebase';

interface MaintenanceInfo {
  isActive: boolean;
  startTime?: Timestamp;
  duration?: number;
  maintenanceId?: string;
}

export const useMaintenanceStatus = (
  options: {
    enabled?: boolean;
    refetchInterval?: number;
  } = {}
) => {
  const { enabled = true, refetchInterval = 3600000 } = options;

  const firestore = useFirestore();
  const [data, setData] = useState<MaintenanceInfo>({ isActive: false });
  const [loading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchMaintenance = useCallback(async () => {
    if (!enabled) return;

    setIsLoading(true);

    try {
      const now = new Date();
      const maintenanceRef = collection(firestore, 'scheduled_maintenance');
      const maintenanceQuery = query(
        maintenanceRef,
        where('active', '==', true),
        where('startTime', '>', now),
        orderBy('startTime', 'asc'),
        limit(1)
      );

      const querySnapshot = await getDocs(maintenanceQuery);

      if (querySnapshot.empty || !querySnapshot.docs[0]) {
        setData({ isActive: false });
      } else {
        const doc = querySnapshot.docs[0];
        const maintenanceData = doc.data() as MaintenanceInfo;

        setData({
          isActive: true,
          startTime: maintenanceData.startTime,
          duration: maintenanceData.duration,
          maintenanceId: doc.id,
        });
      }

      setError(null);
    } catch (err) {
      Sentry.captureException('Error fetching maintenance status:');
      setError(err instanceof Error ? err : new Error('Unknown error'));
    } finally {
      setIsLoading(false);
    }
  }, [enabled, firestore]);

  const refetch = useCallback(() => {
    fetchMaintenance();
  }, [fetchMaintenance]);

  useEffect(() => {
    if (!enabled) return;

    fetchMaintenance();

    const intervalId = setInterval(fetchMaintenance, refetchInterval);

    return () => clearInterval(intervalId);
  }, [enabled, fetchMaintenance, refetchInterval]);

  return {
    data,
    loading,
    error,
    refetch,
  };
};
