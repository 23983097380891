import { gql } from '~anyx/common/graphql';

export const STORE_CATEGORY = gql(/* GraphQL */ `
  query StoreCategory($mdStoreId: Long!) {
    MdStore(id: $mdStoreId) {
      id
      category
    }
  }
`);
